// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--Kf0oH";
export var box__d92PR = "PlasmicPricing-module--box__d92PR--1UrQ3";
export var header = "PlasmicPricing-module--header--3rvc5";
export var section__fgnvS = "PlasmicPricing-module--section__fgnvS--U1NSi";
export var box__wuZoY = "PlasmicPricing-module--box__wuZoY--2dzWb";
export var plan__nFoGn = "PlasmicPricing-module--plan__nFoGn--sIYcO";
export var box__mFrCr = "PlasmicPricing-module--box__mFrCr--10ZP3";
export var box__kiK0D = "PlasmicPricing-module--box__kiK0D--1Y_Tc";
export var bullet__x3R1P = "PlasmicPricing-module--bullet__x3R1P--2kUc8";
export var bullet__mio8K = "PlasmicPricing-module--bullet__mio8K--2cm4B";
export var bullet__dx9HS = "PlasmicPricing-module--bullet__dx9HS--22YAg";
export var plan__suLeX = "PlasmicPricing-module--plan__suLeX--grUqU";
export var box__mkc8A = "PlasmicPricing-module--box__mkc8A--tHMBH";
export var box__hbZh8 = "PlasmicPricing-module--box__hbZh8--1Brub";
export var bullet__cj3JV = "PlasmicPricing-module--bullet__cj3JV--17FZ3";
export var bullet__ev3X = "PlasmicPricing-module--bullet__ev3X--3GSUK";
export var bullet__lDcz = "PlasmicPricing-module--bullet__lDcz--3Shwh";
export var bullet__hi5Eg = "PlasmicPricing-module--bullet__hi5Eg--35Co9";
export var plan__tv9ZZ = "PlasmicPricing-module--plan__tv9ZZ--SN20a";
export var box__xpUxb = "PlasmicPricing-module--box__xpUxb--1ELTK";
export var box__sChp7 = "PlasmicPricing-module--box__sChp7--1WSQy";
export var bullet__facPy = "PlasmicPricing-module--bullet__facPy--cSdN6";
export var bullet__zXnYf = "PlasmicPricing-module--bullet__zXnYf--3GOLc";
export var bullet__pcb5K = "PlasmicPricing-module--bullet__pcb5K--X2oNq";
export var section__fnky1 = "PlasmicPricing-module--section__fnky1--1Ny1r";
export var faq__nAlGj = "PlasmicPricing-module--faq__nAlGj--3Elhw";
export var faq__dTmqg = "PlasmicPricing-module--faq__dTmqg--1vl26";
export var faq__mzfdL = "PlasmicPricing-module--faq__mzfdL--2O-Up";
export var footer = "PlasmicPricing-module--footer--zhosD";