// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--2CVwz";
export var box__qiArK = "PlasmicFeatures-module--box__qiArK--16Go4";
export var header = "PlasmicFeatures-module--header--aLgU4";
export var section = "PlasmicFeatures-module--section--YTT7P";
export var box__lk4S = "PlasmicFeatures-module--box__lk4S--xIjGS";
export var box__ovCFw = "PlasmicFeatures-module--box__ovCFw--VhUq8";
export var box__oWd3 = "PlasmicFeatures-module--box__oWd3--1JyZS";
export var box__aBqc = "PlasmicFeatures-module--box__aBqc--1waR2";
export var bullet__x61Fr = "PlasmicFeatures-module--bullet__x61Fr--LgjgJ";
export var bullet__tqe1E = "PlasmicFeatures-module--bullet__tqe1E--3Ye4s";
export var bullet__ycZas = "PlasmicFeatures-module--bullet__ycZas--vnkTE";
export var box___3Pacj = "PlasmicFeatures-module--box___3Pacj--1eYkh";
export var box__hWzq = "PlasmicFeatures-module--box__hWzq--28K_v";
export var box__xhTxE = "PlasmicFeatures-module--box__xhTxE--3pI5g";
export var img__o03F = "PlasmicFeatures-module--img__o03F--3MFm-";
export var img___5AonO = "PlasmicFeatures-module--img___5AonO--3LUM9";
export var box__giIx = "PlasmicFeatures-module--box__giIx--GCr7f";
export var box__zfjGy = "PlasmicFeatures-module--box__zfjGy--1bmSp";
export var img__waEkm = "PlasmicFeatures-module--img__waEkm--3P2ef";
export var img__zFHgx = "PlasmicFeatures-module--img__zFHgx--2-JwU";
export var box__omZSo = "PlasmicFeatures-module--box__omZSo--1sFVL";
export var box__egn6J = "PlasmicFeatures-module--box__egn6J--2a1m_";
export var box__bShj = "PlasmicFeatures-module--box__bShj--1E1Xj";
export var bullet__ymkK5 = "PlasmicFeatures-module--bullet__ymkK5--jhJBW";
export var bullet__nvgJw = "PlasmicFeatures-module--bullet__nvgJw--1XW37";
export var bullet__uz30U = "PlasmicFeatures-module--bullet__uz30U--3ApRY";
export var box__uoXfE = "PlasmicFeatures-module--box__uoXfE--2TKoQ";
export var box___4Xxsk = "PlasmicFeatures-module--box___4Xxsk--1zkB_";
export var homeCta = "PlasmicFeatures-module--homeCta--_VZ3o";
export var footer = "PlasmicFeatures-module--footer--3Mue7";