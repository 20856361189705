// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--1Vhcn";
export var box___0Ev3T = "PlasmicPlan-module--box___0Ev3T--3-9OX";
export var box___58Dw5 = "PlasmicPlan-module--box___58Dw5--13lHH";
export var slotName = "PlasmicPlan-module--slotName--3Vdmj";
export var box___5Utsh = "PlasmicPlan-module--box___5Utsh--besyE";
export var box__acoc2 = "PlasmicPlan-module--box__acoc2--3-zoR";
export var bullet__uwAre = "PlasmicPlan-module--bullet__uwAre--36avu";
export var bullet__e7Xk2 = "PlasmicPlan-module--bullet__e7Xk2--2V8Sw";
export var bullet__vdmal = "PlasmicPlan-module--bullet__vdmal--1_W9G";
export var box__nB83 = "PlasmicPlan-module--box__nB83--gzpaP";
export var svg__lh3H = "PlasmicPlan-module--svg__lh3H--3wADm";