// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--2B2t-";
export var box__lSXi2 = "PlasmicTopSection-module--box__lSXi2--1Byy5";
export var box__rm02O = "PlasmicTopSection-module--box__rm02O--2EY42";
export var box__aebm = "PlasmicTopSection-module--box__aebm--3-hx4";
export var box__bZrrT = "PlasmicTopSection-module--box__bZrrT--LJ_8G";
export var box__spySk = "PlasmicTopSection-module--box__spySk--34MVJ";
export var box__v1G6P = "PlasmicTopSection-module--box__v1G6P--eu8eM";
export var box__c18VX = "PlasmicTopSection-module--box__c18VX--2nRFy";
export var linkButton__dOcuZ = "PlasmicTopSection-module--linkButton__dOcuZ--nx5vG";
export var linkButton__fooHh = "PlasmicTopSection-module--linkButton__fooHh--zr82-";
export var box__szh88 = "PlasmicTopSection-module--box__szh88--3pjpz";
export var img = "PlasmicTopSection-module--img--TX3Qd";