// extracted by mini-css-extract-plugin
export var root = "PlasmicLinkButton-module--root--ucDMT";
export var root__type_solidBlue = "PlasmicLinkButton-module--root__type_solidBlue--YrzsS";
export var root__type_solidBlack = "PlasmicLinkButton-module--root__type_solidBlack--3zm5A";
export var root__type_outlineBlue = "PlasmicLinkButton-module--root__type_outlineBlue--PqhIT";
export var root__type_small = "PlasmicLinkButton-module--root__type_small--eoaar";
export var root__narrower = "PlasmicLinkButton-module--root__narrower--2EjHs";
export var slotText = "PlasmicLinkButton-module--slotText--3zgnI";
export var slotText__type_solidBlue = "PlasmicLinkButton-module--slotText__type_solidBlue--2ntsh";
export var slotText__type_solidBlack = "PlasmicLinkButton-module--slotText__type_solidBlack--3Y3mk";
export var slotText__type_outlineBlue = "PlasmicLinkButton-module--slotText__type_outlineBlue--3SQ16";