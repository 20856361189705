// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--2Nkxw";
export var box__q084Y = "PlasmicFooter-module--box__q084Y--2LrT-";
export var box__cq3Ny = "PlasmicFooter-module--box__cq3Ny--2rbQc";
export var box__mRNuf = "PlasmicFooter-module--box__mRNuf--16CcC";
export var iconLink__qddBz = "PlasmicFooter-module--iconLink__qddBz--zEzD0";
export var svg__rzmHf = "PlasmicFooter-module--svg__rzmHf--1DGzG";
export var box__jkKwr = "PlasmicFooter-module--box__jkKwr--3NUGB";
export var link__zDOwE = "PlasmicFooter-module--link__zDOwE--2Tb0C";
export var box__dd0Yo = "PlasmicFooter-module--box__dd0Yo--s1Z1x";
export var link__l3Qq1 = "PlasmicFooter-module--link__l3Qq1--1OqRW";
export var box__pZMn = "PlasmicFooter-module--box__pZMn--xP1hW";
export var box__gp9Nm = "PlasmicFooter-module--box__gp9Nm--2JbHc";
export var box__feH9B = "PlasmicFooter-module--box__feH9B--fRaMb";
export var link__ab8An = "PlasmicFooter-module--link__ab8An--xe5gz";
export var link__iTxUp = "PlasmicFooter-module--link__iTxUp--32M1g";
export var link__wjQdv = "PlasmicFooter-module--link__wjQdv--2202p";
export var link__e4GIy = "PlasmicFooter-module--link__e4GIy--UZPoe";
export var link__wRzUs = "PlasmicFooter-module--link__wRzUs--3chb3";
export var box__hd5R = "PlasmicFooter-module--box__hd5R--1Nyxy";
export var box__wkS8H = "PlasmicFooter-module--box__wkS8H--1ipYK";
export var link__kgA6 = "PlasmicFooter-module--link__kgA6--2OB3C";
export var link___7KoVu = "PlasmicFooter-module--link___7KoVu--1_cTU";
export var link__fyN7 = "PlasmicFooter-module--link__fyN7--2fIhP";
export var link__y3OkT = "PlasmicFooter-module--link__y3OkT--2z5pZ";
export var link__hf6Hk = "PlasmicFooter-module--link__hf6Hk--krWQl";
export var box__hvn3X = "PlasmicFooter-module--box__hvn3X--qomP6";
export var box__j85Fp = "PlasmicFooter-module--box__j85Fp--1kBvH";
export var link__gyOxE = "PlasmicFooter-module--link__gyOxE--m9ZsG";
export var link__vZuo0 = "PlasmicFooter-module--link__vZuo0--269A-";
export var link___8QdSw = "PlasmicFooter-module--link___8QdSw--3Pror";
export var link__k5Raw = "PlasmicFooter-module--link__k5Raw--2L4WA";
export var link__qk8AF = "PlasmicFooter-module--link__qk8AF--3gGgV";
export var box__ox0Ai = "PlasmicFooter-module--box__ox0Ai--27Y2o";
export var box__sgaTx = "PlasmicFooter-module--box__sgaTx--13J7w";
export var box__l18Yn = "PlasmicFooter-module--box__l18Yn--1erQ6";
export var box__odxyt = "PlasmicFooter-module--box__odxyt--3RNT5";
export var textbox = "PlasmicFooter-module--textbox--2iVbX";
export var box___8ZJpk = "PlasmicFooter-module--box___8ZJpk--2XDJX";
export var button = "PlasmicFooter-module--button--Lj5uW";
export var svg___9XtCm = "PlasmicFooter-module--svg___9XtCm--15I74";
export var box__bxQc0 = "PlasmicFooter-module--box__bxQc0--vauUS";
export var box__pVTsA = "PlasmicFooter-module--box__pVTsA--3uIxa";
export var box___2RRh = "PlasmicFooter-module--box___2RRh--3X7ZE";
export var link__l42 = "PlasmicFooter-module--link__l42--34rKj";
export var link__ccTzO = "PlasmicFooter-module--link__ccTzO--2HsDM";
export var box___0HkU = "PlasmicFooter-module--box___0HkU--1EWj9";
export var box__x6Ww = "PlasmicFooter-module--box__x6Ww--3Vqg7";
export var iconLink__yaX8W = "PlasmicFooter-module--iconLink__yaX8W--XGrsF";
export var svg__vPpg = "PlasmicFooter-module--svg__vPpg--c3V-w";
export var iconLink__hhjTw = "PlasmicFooter-module--iconLink__hhjTw--3FzXC";
export var svg__h1Lh = "PlasmicFooter-module--svg__h1Lh--31ltl";
export var iconLink__o0PaL = "PlasmicFooter-module--iconLink__o0PaL--g4LYj";
export var svg__luHNj = "PlasmicFooter-module--svg__luHNj--2AJrh";