// extracted by mini-css-extract-plugin
export var all = "plasmic__default_style-module--all--1UbrG";
export var img = "plasmic__default_style-module--img--3BDX8 plasmic__default_style-module--all--1UbrG";
export var input = "plasmic__default_style-module--input--1PVjg plasmic__default_style-module--all--1UbrG";
export var textarea = "plasmic__default_style-module--textarea--1cz8Q plasmic__default_style-module--all--1UbrG";
export var button = "plasmic__default_style-module--button--3_B5- plasmic__default_style-module--all--1UbrG";
export var code = "plasmic__default_style-module--code--1LHH8 plasmic__default_style-module--all--1UbrG";
export var pre = "plasmic__default_style-module--pre--2aVdG plasmic__default_style-module--all--1UbrG";
export var h1 = "plasmic__default_style-module--h1--2h2tI plasmic__default_style-module--all--1UbrG";
export var h2 = "plasmic__default_style-module--h2--112cp plasmic__default_style-module--all--1UbrG";
export var h3 = "plasmic__default_style-module--h3---Fie0 plasmic__default_style-module--all--1UbrG";
export var h4 = "plasmic__default_style-module--h4--1yYvn plasmic__default_style-module--all--1UbrG";
export var h5 = "plasmic__default_style-module--h5--12U7O plasmic__default_style-module--all--1UbrG";
export var h6 = "plasmic__default_style-module--h6--27iIr plasmic__default_style-module--all--1UbrG";
export var address = "plasmic__default_style-module--address--2aj4K plasmic__default_style-module--all--1UbrG";
export var ol = "plasmic__default_style-module--ol--3i5It plasmic__default_style-module--all--1UbrG";
export var ul = "plasmic__default_style-module--ul--3kAOj plasmic__default_style-module--all--1UbrG";
export var select = "plasmic__default_style-module--select--1O5j4 plasmic__default_style-module--all--1UbrG";
export var li = "plasmic__default_style-module--li--6LkRE plasmic__default_style-module--all--1UbrG";
export var plasmic_default__component_wrapper = "plasmic__default_style-module--plasmic_default__component_wrapper--3eZ8u";
export var __wab_text = "plasmic__default_style-module--__wab_text--2GF2O";
export var plasmic_page_wrapper = "plasmic__default_style-module--plasmic_page_wrapper--vaaM6";