// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--2o9Zx";
export var root__long = "PlasmicFeatureCard-module--root__long--1alJX";
export var box___4NpzO = "PlasmicFeatureCard-module--box___4NpzO--3d1to";
export var box__yAi7E = "PlasmicFeatureCard-module--box__yAi7E--i9Ofh";
export var svg___5RZbH = "PlasmicFeatureCard-module--svg___5RZbH--gk2R0";
export var box___3FwlI = "PlasmicFeatureCard-module--box___3FwlI--2zQUe";
export var box__long___3FwlIAvq4 = "PlasmicFeatureCard-module--box__long___3FwlIAvq4--1E0mL";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--f-M6A";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--ek_se";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--1CZ_e";
export var box__rZGdc = "PlasmicFeatureCard-module--box__rZGdc--1eCGK";
export var box__long__rZGdcAvq4 = "PlasmicFeatureCard-module--box__long__rZGdcAvq4--1rbSg";
export var svg__jsmgm = "PlasmicFeatureCard-module--svg__jsmgm--pk4CF";